<script setup lang="ts">
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";

const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  return (locales.value).filter(i => i.code !== locale.value)
})

const currentLocale = computed(() => {
  return (locales.value).filter(i => i.code === locale.value)[0]
})
</script>

<template>
  <ClientOnly>
    <template #fallback>
      <div class="rounded-lg w-[60px] h-[18px] bg-gray-100 animate-pulse"></div>
    </template>

    <Menu as="div" class="relative group">
      <MenuButton class="flex items-center group-hover:bg-gray-200 space-x-1 px-1.5 py-1 rounded-md">
        <img class="h-3.5 w-auto object-contain rounded" :src="`/images/country/${locale}.svg`" :alt="locale" loading="lazy"/>
        <span class="uppercase font-medium text-xs">{{currentLocale?.code}}</span>
      </MenuButton>

      <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
      >
        <MenuItems class="absolute right-0 p-2 mt-2 w-36 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-400">
          <div class="absolute bottom-full w-5 right-0 overflow-hidden inline-block">
            <div class="size-2.5 bg-white border rotate-45 transform origin-bottom-left"></div>
          </div>

          <MenuItem v-for="locale in availableLocales" :key="locale.code">
            <NuxtLink
                class="text-primary hover:text-secondary flex items-center px-1.5 py-0.5 space-x-2 text-sm hover:bg-gray-100 font-normal w-full rounded"
                :to="switchLocalePath(locale.code)"
            >
              <img class="h-3.5 w-5" :src="`/images/country/${locale.code}.svg`" :alt="locale.name" loading="lazy" />
              <span>{{ locale.name }}</span>
            </NuxtLink>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </ClientOnly>
</template>