<template>
  <div class="lb-top-menu-wrapper" id="topNavigation">
    <div class="lb-top-menu">
      <div class="flex items-center">
        <LucidePhone class="h-3 w-3 mr-2 text-primary"/>
        <div class="hidden md:inline-block text-primary">{{ $t('navigation.support-phone') }} </div>
        <a class="text-secondary ml-2 " :href="`tel:${phoneNumber}`">
          {{phoneNumber}}
        </a>
      </div>

      <div class="flex items-center space-x-8">
        <ul role="list" class="text-center justify-between items-center hidden sm:flex space-x-8">
          <li v-for="(item, index) in topMenu" :key="`ics-101${index}`">
            <NuxtLinkLocale :to="getLocaleString(item.href)" class="flex items-center justify-center text-center flex-wrap">
              <NuxtImg :src="item.custom?.image" height="16" width="16" class="mr-1.5" v-if="item.custom?.image" />
              <span>{{getLocaleString(item.name)}}</span>
            </NuxtLinkLocale>
          </li>
        </ul>

        <LangSwitcher v-if="languageSelectionIsActive" />
      </div>

    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { isB2BAccount, topMenu, contactConfig, languageSelectionIsActive } = storeToRefs(storeConfig)
const phoneNumber = computed(() => contactConfig.value?.phone ? getLocaleString(contactConfig.value?.phone) : "")
</script>
